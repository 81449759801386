@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  body {
    @apply bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-100;
  }

  p {
    @apply text-gray-600 dark:text-gray-400 leading-relaxed text-base mb-4;
  }

  h1 {
    @apply text-3xl font-bold mb-6 mt-8 text-gray-900 dark:text-gray-100;
  }

  h2 {
    @apply text-2xl font-semibold mb-4 mt-6 text-gray-800 dark:text-gray-200;
  }

  h3 {
    @apply text-xl font-medium mb-3 mt-5 text-gray-800 dark:text-gray-200;
  }
}

/* Component styles */
@layer components {
  .App {
    @apply text-center;
  }

  .App-logo {
    @apply h-40 pointer-events-none;
  }

  .App-header {
    @apply bg-gray-800 min-h-screen flex flex-col items-center justify-center text-xl text-white;
  }

  .App-link {
    @apply text-blue-400;
  }
}

/* Utility styles */
@layer utilities {
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
}
